<template>
  <div class="raffles-list">
    <ShopCard
      :url="'/shop/SeaMT'"
      :img="img"
      :name="'SEA-MT'"
      :description="'A psychoactive substance used in ancient rituals'"
    ></ShopCard>
    <ShopCard
      :url="'/shop/AquaPipe'"
      :img="'https://img.coraltribe.io/aquapipe.jpg'"
      :name="'AQUA PIPE'"
      :description="'A shamanic pipe used to channel Sea-MT'"
    ></ShopCard>
  </div>
</template>

<script>
import ShopCard from "../components/ShopCard.vue";

import axios from "axios";

let config_axios = {
  headers: {
    "Content-Type": "application/json;",
  },
};

export default {
  name: "Raffles",
  components: {
    ShopCard,
  },
  data: function () {
    return {
      img: "https://img.coraltribe.io/Sea-MT-THREATENED.jpg",
    };
  },
  mounted: function () {
    var component = this;

    // get live raffle
    axios
      .get(
        "https://marketplace.bluediamonds.app/cct/trip/get_water_condition_image"
      )
      .then(function (result) {
        console.log(result);

        component.img = "https://img.coraltribe.io/" + result.data.image;
      });
  },
};
</script>

<style scoped></style>
