<template>
	<a class="raffle-card-background" :href="url">
		<div class="raffle-card">
			<div class="raffle-img">
				<img :src="img"/>
			</div>
			<span class="raffle-title" style="min-height: 60px;display: flex;flex-direction: column;justify-content: space-around;">{{ name }}</span>
			<div class="raffle-line"></div>
			
			<div style="color: white;text-align: center; height: 50px;">{{ description }}</div>
			
			<a :href="url" class="raffle-button button">
				<div class="button-left-border"></div>
				<div class="button-right-border"></div>
				<div class="button-content">
					<span>VIEW MORE</span>
				</div>
			</a>
			
		</div>
	</a>
</template>

<script>



export default {
  name: 'ShopCard',
  props : {
	url : {
		type: String,
		default: ''
	},
	img : {
		type: String,
		default: ''
	},
	name : {
		type: String,
		default: ''
	},
	description : {
		type: String,
		default: ''
	},

  },
  methods: {
	
	
  },
  mounted: function() {
	
  }
}
</script>

<style scoped>

</style>